@import '../campaign';

.website {
  .previewContainer {
    .preview.template {
      .banner {
        padding-bottom: 40%;
        position: relative;
        background-size: cover;
        background-position: center;

        .logo {
          position: absolute;
          left: 30px;
          top: 30px;
        }
      }

      .interested {
        position: absolute;
        right: 5%;
        bottom: 20px;
        background-color: #fff;
        padding: 30px;
        font-size: 22px;
        color: #007ee5;
        text-align: center;

        .btn {
          background: linear-gradient(to left, #007ee5, #05a0ff);
          width: 350px;
          display: block;
          margin-top: 30px;
          padding: 15px;
          color: white;
        }
      }

      .title {
        margin-top: 100px;
        font-size: 44px;
      }

      .text {
        margin-top: 50px;
      }

      .title,
      .text {
        font-family: AvenirNext, sans-serif;
        color: #666;
      }

      .content {
        margin: 50px 10%;
      }

      .contact {
        margin: 50px auto;
        width: 750px;
        max-width: 95%;

        .title {
          font-size: 48px;
          text-align: center;
          color: #141720;

          span {
            font-weight: bold;
          }
        }

        input[type=text],
        textarea {
          outline: none;
          padding: 16px 24px;
          font-size: 20px;
          border: 2px solid #d4d4d4;
          width: 100%;
          border-radius: 5px;
          color: #666;
          display: block;
          margin-bottom: 30px;
        }

        input[type=submit] {
          background-color: #007ee5;
          padding: 20px 0;
          color: #fff;
          display: block;
          width: 213px;
          text-decoration: none;
          transition: opacity 0.1s ease;
          margin: 0 auto 50px;
          outline: none;
          border: none;
          border-radius: 100px;
          cursor: pointer;
        }
      }

      .gallery {
        margin-top: 50px;

        .items {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .items {
            width: 49%;
          }

          .item {
            width: 48%;
            padding-top: 37.5%;
            display: block;
            margin-bottom: 15px;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }
  }
}
